import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Paper, TablePagination, Typography, Button
} from '@mui/material';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

export const PayoutTable = ({ split }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [loading, setLoading] = useState(false);
    const [payouts, setPayouts] = useState([]);

    useEffect(() => {
        const fetchPayouts = async () => {
            if (split.payouts && split.payouts.length > 0) {
                console.log('Fetching payouts:', split.payouts);
                const db = getFirestore();
                const payoutsData = await Promise.all(
                    split.payouts.map(async (payoutId) => {
                        const payoutDoc = await getDoc(doc(db, 'payouts', payoutId));
                        return { id: payoutDoc.id, ...payoutDoc.data() };
                    })
                );
                setPayouts(payoutsData);
            } else {
                // If split.payouts is empty, clear the payouts state
                setPayouts([]);
            }
        };

        fetchPayouts();
    }, [split]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleCreatePayout = async () => {
        setLoading(true);
        try {
            const functions = getFunctions();
            const createTestPayout = httpsCallable(functions, 'createTestPayout');
            await createTestPayout({ splitId: split.id });

            // Re-fetch payouts after creating new payout
            const db = getFirestore();
            const updatedSplitDoc = await getDoc(doc(db, 'splits', split.id));
            const updatedPayouts = await Promise.all(
                (updatedSplitDoc.data().payouts || []).map(async (payoutId) => {
                    const payoutDoc = await getDoc(doc(db, 'payouts', payoutId));
                    return { id: payoutDoc.id, ...payoutDoc.data() };
                })
            );
            setPayouts(updatedPayouts);
        } catch (error) {
            console.error('Error creating payout:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Typography variant="h6" align="center" gutterBottom>Payout History</Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={handleCreatePayout}
                disabled={loading}
                sx={{ marginBottom: 2 }}
            >
                {loading ? 'Creating Payout...' : 'Create Payout'}
            </Button>
            {payouts.length > 0 ? (
                <>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Payout Date</TableCell>
                                    <TableCell>Payout Amount</TableCell>
                                    <TableCell>Agency Cut</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {payouts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((payout) => (
                                    <TableRow key={payout.id}>
                                        <TableCell>{payout.date ? new Date(payout.date.seconds * 1000).toLocaleString() : 'Pending'}</TableCell>
                                        <TableCell>{payout.amount}</TableCell>
                                        <TableCell>{payout.agencyCut}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={payouts.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            ) : (
                <Typography variant="body1" align="center">No payouts available</Typography>
            )}
        </Paper>
    );
};

export default PayoutTable;




