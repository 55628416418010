import React, { useState, useEffect } from "react";
import { Container, Box, Typography, Button, Grid } from "@mui/material";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, updateDoc, getDoc, arrayUnion, collection, addDoc } from "firebase/firestore";
import { checkPaymentMethod, getClientSecret, createStripeCustomer } from "../services/payment"; // Assume you have these functions defined elsewhere
import { useNavigate, useLocation } from "react-router-dom";
import PaymentComponent from "./payment/PaymentComponent";
import LinkAccount from "./linkAccount"; // Ensure this import is correct
import { getFunctions, httpsCallable } from 'firebase/functions';

const AcceptSplitComponent = ({ splitData }) => {
    const [clientSecret, setClientSecret] = useState(null);
    const [error, setError] = useState(null);
    const [hasPaymentMethod, setHasPaymentMethod] = useState(null);
    const [hasLinkedAccount, setHasLinkedAccount] = useState(true); // Initially assume the user has a linked account
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const checkUserDetails = async () => {
            const auth = getAuth();
            const firestore = getFirestore();

            try {
                const user = auth.currentUser;
                if (user) {
                    const userRef = doc(firestore, "users", user.uid);

                    // Fetch the user document to get stripeCreatorCustomerId and linkedAccount
                    const userDoc = await getDoc(userRef);
                    if (!userDoc.exists()) {
                        throw new Error("User not found");
                    }

                    const linkedAccount = userDoc.data().linkedAccount;

                    if (!linkedAccount) {
                        setHasLinkedAccount(false);
                    } else {
                        setHasLinkedAccount(true);
                    }

                    console.log('Has linked account: ', hasLinkedAccount)


                    const userEmail = userDoc.data().email;
                    const displayName = userDoc.data().displayName;

                    let customerId = userDoc.data().stripeCreatorCustomerId
                    console.log(customerId)
                    if (!customerId) {
                        // Create Stripe customer and store the customerId in db linked to current user
                        customerId = await createStripeCustomer(userEmail, displayName);
                        console.log(customerId)
                        await updateDoc(userRef, {
                            stripeCreatorCustomerId: customerId
                        });
                    }

                    // Check if the user has a payment method
                    const hasPayment = await checkPaymentMethod(customerId);
                    console.log('Has payment: ', hasPayment)
                    setHasPaymentMethod(hasPayment);
                    if (!hasPayment) {
                        // Create a setup intent and get the client secret to run Stripe UI for credit cards.
                        const secret = await getClientSecret(customerId);
                        if (secret) {
                            setClientSecret(secret);
                        }
                    }
                }
            } catch (err) {
                setError("Error checking user details");
                console.error(err);
            }
        };

        checkUserDetails();
    }, [hasLinkedAccount]);

    const handleAccept = async () => {
        const auth = getAuth();
        const firestore = getFirestore();

        try {
            const user = auth.currentUser;
            if (user && splitData) {
                const userRef = doc(firestore, "users", user.uid);

                // Fetch the user document to get stripeCreatorCustomerId and linkedAccount
                const userDoc = await getDoc(userRef);
                if (!userDoc.exists()) {
                    throw new Error("User not found");
                }

                // If the user has a payment method, continue the process
                // Add user reference to the split document
                const splitRef = doc(firestore, "splits", splitData.id);
                await updateDoc(splitRef, {
                    users: arrayUnion(userRef),
                    status: "Active",
                    creatorId: user.uid,
                });

                // Set user role to 'Creator'
                await updateDoc(userRef, {
                    activeSplitId: splitRef.id,
                    role: 'Creator'
                });

                // Fetch manager's email using managerId from splitData
                const managerRef = doc(firestore, "users", splitData.managerId);
                const managerDoc = await getDoc(managerRef);
                if (!managerDoc.exists()) {
                    throw new Error("Manager not found");
                }
                const managerEmail = managerDoc.data().email;

                // Create a 'mail' document to send an email to the manager
                await addDoc(collection(firestore, "mail"), {
                    to: managerEmail,
                    message: {
                        subject: "Split Accepted",
                        html: `<p>Your split has been accepted by ${user.email}.</p>`,
                    },
                });

                const functions = getFunctions();
                const setSplitsToInactive = httpsCallable(functions, 'setSplitsToInactive');                
                setSplitsToInactive();
                
                // Refresh the page if already on '/'
                if (location.pathname === '/') {
                    window.location.reload();
                } else {
                    navigate("/"); // Redirect to home page
                }
            }
        } catch (err) {
            setError("Error accepting split");
            console.error(err);
        }
    };

    const handleReject = async () => {
        const auth = getAuth();
        const firestore = getFirestore();

        try {
            const user = auth.currentUser;
            if (user && splitData) {
                const splitRef = doc(firestore, "splits", splitData.id);

                // Update the split status to 'Rejected'
                await updateDoc(splitRef, {
                    status: "Rejected",
                });

                // Refresh the page if already on '/'
                if (location.pathname === '/') {
                    window.location.reload();
                } else {
                    navigate("/"); // Redirect to home page
                }
            }
        } catch (err) {
            setError("Error rejecting split");
            console.error(err);
        }
    };

    return (
        <Container>
            <Box mt={4} mb={4}>
                {error && (
                    <Typography variant="body2" color="error" paragraph>
                        {error}
                    </Typography>
                )}
                {splitData ? (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h4" gutterBottom>
                                Accept Split Invitation
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" paragraph>
                                <strong>Manager:</strong> {splitData.managerName} <br />
                                <strong>Manager's Split:</strong> {splitData.managerSplit}%
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" paragraph>
                                <strong>Creator:</strong> {splitData.creatorName} <br />
                                <strong>Your Split:</strong> {splitData.creatorSplit}%
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAccept}
                                disabled={!hasPaymentMethod || !hasLinkedAccount}
                                style={{ marginRight: '10px' }}
                            >
                                Accept Split
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleReject}
                            >
                                Reject Split
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            {clientSecret && <PaymentComponent clientSecret={clientSecret} />}
                        </Grid>
                        <Grid item xs={12}>
                            {!hasLinkedAccount && <LinkAccount />}
                        </Grid>
                    </Grid>
                ) : (
                    <Typography variant="body1" paragraph>
                        Loading split data...
                    </Typography>
                )}
            </Box>
        </Container>
    );
};

export default AcceptSplitComponent;


