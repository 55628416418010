import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';

const DashboardGrid = ({ dashboardData }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Paper sx={{ padding: 2, textAlign: 'center' }}>
          <Typography variant="h6">Total Split Earnings</Typography>
          <Typography variant="h4">${dashboardData.totalEarnings}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper sx={{ padding: 2, textAlign: 'center' }}>
          <Typography variant="h6">Last Payout</Typography>
          <Typography variant="h4">${dashboardData.lastPayout}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper sx={{ padding: 2, textAlign: 'center' }}>
          <Typography variant="h6">Active Splits</Typography>
          <Typography variant="h4">{dashboardData.activeSplits}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper sx={{ padding: 2, textAlign: 'center' }}>
          <Typography variant="h6">Pending Splits</Typography>
          <Typography variant="h4">{dashboardData.pendingSplits}</Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default DashboardGrid;
