import { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

export const useFetchLinkedAccount = () => {
    const [linkedAccount, setLinkedAccount] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchLinkedAccount = async () => {
            try {
                const auth = getAuth();
                const user = auth.currentUser;
                if (user) {
                    const functions = getFunctions();
                    const checkLinkedAccount = httpsCallable(functions, 'userHasLinkedAccount');
                    const response = await checkLinkedAccount();
                    setLinkedAccount(response.data.hasLinkedAccount);
                } else {
                    setLinkedAccount(null);
                }
            } catch (error) {
                console.error('Error fetching linked account:', error);
                setLinkedAccount(null);
            } finally {
                setLoading(false);
            }
        };

        fetchLinkedAccount();
    }, []);

    return { linkedAccount, loading };
};

export default useFetchLinkedAccount;

