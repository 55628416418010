import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import {
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { getTransactions } from "../plaidUtils";
import PaymentComponent from "./payment/PaymentComponent";

export default function GetTransactions() {
    const [transactions, setTransactions] = useState([]);

    const handleGetTransactions = async () => {
        try {
            const transactions = await getTransactions();
            console.log('Transactions: ', transactions)
            setTransactions(transactions ?? []);
        } catch (error) {
            console.error("Fetch error:", error);
        }
    };

    return (
        <>
            <ListItemButton onClick={handleGetTransactions}>
                <ListItemIcon>
                    <AccountBalanceWalletIcon />
                </ListItemIcon>
                <ListItemText
                    primary={<Typography color="textPrimary">Get Transactions</Typography>}
                />
            </ListItemButton>
            {Array.isArray(transactions) && transactions.length > 0 && (
                <div>
                    <h3>Transactions</h3>
                    <ul>
                        {transactions.map((transaction, index) => (
                            <li key={index}>
                                {transaction.authorized_date} - {transaction.merchant_name}: ${transaction.amount}
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {/* temporary import */}
            <PaymentComponent />
        </>
    );
}
