import React, { useState } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';

export default function CheckoutForm({ clientSecret }) {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [accountHolderName, setAccountHolderName] = useState('');
  const [email, setEmail] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    setErrorMessage(null);

    try {
      const { error, setupIntent } = await stripe.collectBankAccountForSetup({
        clientSecret: clientSecret,
        params: {
          payment_method_type: 'us_bank_account',
          payment_method_data: {
            billing_details: {
              name: accountHolderName,
              email: email,
            },
          },
        },
        expand: ['payment_method'],
      });

      if (error) {
        setErrorMessage(error.message);
      } else if (setupIntent.status === 'requires_payment_method') {
        setErrorMessage('Customer canceled the hosted verification modal. Please try another payment method.');
      } else if (setupIntent.status === 'requires_confirmation') {
        setShowConfirmation(true);
      }
    } catch (error) {
      setErrorMessage('An unexpected error occurred.');
      console.error('Error during setup:', error);
    }

    setIsLoading(false);
  };

  const handleConfirmationSubmit = async (e) => {
    e.preventDefault();

    if (!stripe) {
      return;
    }

    setIsLoading(true);
    setErrorMessage(null);

    try {
      const { setupIntent, error } = await stripe.confirmUsBankAccountSetup(clientSecret);

      if (error) {
        setErrorMessage(error.message);
        console.error('Error during confirmation:', error);
      } else if (setupIntent.status === 'requires_payment_method') {
        setErrorMessage('Confirmation failed. Please try again with a different payment method.');
      } else if (setupIntent.status === 'succeeded') {
        console.log('Confirmation succeeded! The account is now saved.');
        setShowConfirmation(false);
        window.location.reload();
      } else if (setupIntent.next_action?.type === 'verify_with_microdeposits') {
        console.log('The account needs to be verified via microdeposits.');
        // Display a message to the consumer with next steps.
      }
    } catch (error) {
      setErrorMessage('An unexpected error occurred during confirmation.');
      console.error('Error during confirmation:', error);
    }

    setIsLoading(false);
  };

  return (
    <div style={{ maxWidth: '600px', margin: 'auto', padding: '20px', border: '1px solid #ccc', borderRadius: '8px', lineHeight: '1.6' }}>
      {showConfirmation ? (
        <form id="confirmation-form" onSubmit={handleConfirmationSubmit}>
          <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Confirm the Terms</h2>
          <p style={{ marginBottom: '20px' }}>
            By clicking [accept], you authorize Splvts LLC to debit the bank account specified above for any amount owed
            for charges arising from your use of Rocket Rides’ services and/or purchase of products from Splvts LLC, pursuant
            to Splvts LLC’s website and terms, until this authorization is revoked. You may amend or cancel this authorization
            at any time by providing notice to Splvts LLC with 30 (thirty) days notice.
            <br /><br />
            If you use Splvts LLC’s services or purchase additional products periodically pursuant to Splvts LLC’s terms, you
            authorize Splvts LLC to debit your bank account periodically. Payments that fall outside of the regular debits authorized
            above will only be debited after your authorization is obtained.
          </p>
          <label style={{ display: 'block', marginBottom: '20px' }}>
            <input type="checkbox" required />
            I accept the above information.
          </label>
          {errorMessage && <div className="error-message" style={{ color: 'red', marginBottom: '16px' }}>{errorMessage}</div>}
          <button
            type="submit"
            className="primaryBtn"
            disabled={isLoading}
            style={{
              width: '100%',
              padding: '12px',
              backgroundColor: '#007bff',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            {isLoading ? 'Processing...' : 'Accept'}
          </button>
        </form>
      ) : (
        <form id="payment-form" onSubmit={handleSubmit}>
          <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Enter Your Billing Details</h2>
          <div style={{ marginBottom: '16px' }}>
            <label style={{ display: 'block', marginBottom: '8px' }}>Name</label>
            <input
              type="text"
              value={accountHolderName}
              onChange={(e) => setAccountHolderName(e.target.value)}
              required
              style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }}
            />
          </div>
          <div style={{ marginBottom: '16px' }}>
            <label style={{ display: 'block', marginBottom: '8px' }}>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }}
            />
          </div>
          {errorMessage && <div className="error-message" style={{ color: 'red', marginBottom: '16px' }}>{errorMessage}</div>}
          <div style={{ textAlign: 'center' }}>
            <button
              type="submit"
              className="primaryBtn"
              disabled={isLoading || !stripe || !elements}
              style={{
                width: '100%',
                padding: '12px',
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              {isLoading ? 'Processing...' : 'Continue'}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}



