import './App.css';
import firebaseConfig from "./firebaseConfig.json";
import { pathnames, ActionPages, AppTemplate, AuthProvider, AuthRoutes, FireactProvider, PublicTemplate, ResetPassword, SignIn, SignUp, UserMenu, UserProfile, UserUpdateEmail, UserUpdateName, UserUpdatePassword, UserDelete } from '@fireactjs/core';
import { MainMenu } from './components/mainMenu';
import { CreateSplit } from './components/createSplit';
import { SelectRole } from './components/selectRole';
import { HomeDashboard } from './components/homeDashboard';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { AcceptSplit } from './components/AcceptSplit';
import { Help } from './components/Help';
import { ManagePlaidAccount } from './components/ManagePlaidAccount';
import { LandingPage } from './components/LandingPage';
import { BrowserRouter, Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { CircularProgress, Box } from '@mui/material';
import authMethods from "./authMethods.json";

const Logo = ({ size, color }) => {
	const logoColor = color || 'warning';
	return (
		<LocalFireDepartmentIcon color={logoColor} fontSize={size} />
	);
}

const Loader = ({ size }) => {
	let cpSize = "35px";
	switch (size) {
		case "small":
			cpSize = "30px";
			break;
		case "medium":
			cpSize = "35px";
			break;
		case "large":
			cpSize = "45px";
			break;
		default:
			cpSize = "35px";
			break;
	}
	return (
		<Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
			<CircularProgress color="warning" size={cpSize} />
			<div style={{ position: "absolute" }}>
				<Logo size={size} />
			</div>
		</Box>
	);
}

function App() {
  const config = {
    firebaseConfig: firebaseConfig,
    brand: "Splvts",
    pathnames: pathnames,
    authProviders: authMethods
  };

  return (
    <FireactProvider config={config}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route element={<AuthRoutes loader={<Loader size="large" />} />} >
              <Route element={<AppTemplate logo={<Logo size="large" />} toolBarMenu={<UserMenu />} drawerMenu={<MainMenu />} />}>
                <Route exact path="/" element={<HomeDashboard />} />
                <Route exact path={pathnames.UserProfile} element={<UserProfile />} />
                <Route exact path={pathnames.UserUpdateEmail} element={<UserUpdateEmail />} />
                <Route exact path={pathnames.UserUpdateName} element={<UserUpdateName />} />
                <Route exact path={pathnames.UserUpdatePassword} element={<UserUpdatePassword />} />
                <Route exact path={pathnames.UserDelete} element={<UserDelete />} />
				        <Route exact path="/create-split" element={<CreateSplit />} />
                <Route exact path="/select-role" element={<SelectRole />} />
                <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route exact path="/accept-split" element={<AcceptSplit />} />
                <Route exact path="/help" element={<Help />} />
                <Route exact path="/manage_plaid_account" element={<ManagePlaidAccount />} />
              </Route>
            </Route>
            <Route element={<PublicTemplate />}>
              <Route path={pathnames.SignIn} element={<SignIn logo={<Logo size="large" />} />} />
              <Route path={pathnames.SignUp} element={<SignUp logo={<Logo size="large" />} />} />
              <Route path={pathnames.ResetPassword} element={<ResetPassword logo={<Logo size="large" />} />} />
              <Route path={pathnames.ActionPages} element={<ActionPages logo={<Logo size="large" />} />} />
              <Route path="/info" element={<LandingPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </FireactProvider>
  );
}

export default App;
