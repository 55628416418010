import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container } from "@mui/material";
import {
  getFirestore,
  doc,
  getDoc,
} from "firebase/firestore";
import AcceptSplitComponent from "./AcceptSplitComponent";

export const AcceptSplit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [splitData, setSplitData] = useState(null);

  useEffect(() => {
    const fetchSplitData = async () => {
      const params = new URLSearchParams(location.search);
      const splitId = params.get("splitId") || "";
      const firestore = getFirestore();

      try {
        const splitRef = doc(firestore, "splits", splitId);
        const splitDoc = await getDoc(splitRef);

        if (splitDoc.exists()) {
          setSplitData({ id: splitId, ...splitDoc.data() });
        } else {
          if (location.pathname === '/') {
            window.location.reload();
          } else {
            navigate("/"); // Redirect to home page
          }        }
      } catch (err) {
        // Refresh the page if already on '/'
        if (location.pathname === '/') {
          window.location.reload();
        } else {
          navigate("/"); // Redirect to home page
        }
      }
    };

    fetchSplitData();
  }, [location, navigate]);

  return (
    <Container>
      <AcceptSplitComponent splitData={splitData} />
    </Container>
  );
};

export default AcceptSplit;
