import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { getFunctions, httpsCallable } from 'firebase/functions';
import LinkAccount from "./linkAccount";

export const ManagePlaidAccount = () => {
    const [accountDeleted, setAccountDeleted] = useState(false);

    const handleDeleteAccounts = async () => {
        const functions = getFunctions();
        const deleteLinkedAccounts = httpsCallable(functions, 'deleteLinkedAccounts');

        try {
            await deleteLinkedAccounts();
            setAccountDeleted(true); // Set the state to true if deletion is successful
        } catch (error) {
            console.error('Error deleting linked accounts:', error);
        }
    };

    if (accountDeleted) {
        return <LinkAccount />;
    }

    return (
        <Box
            sx={{
                maxWidth: '400px',
                margin: 'auto',
                padding: '20px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                textAlign: 'center',
            }}
        >
            <Typography variant="h4" gutterBottom>
                Manage Bank Account
            </Typography>
            <Typography variant="body1" paragraph>
                You can change your connected bank account by clicking the button below.
            </Typography>
            <Box>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AccountBalanceIcon />}
                    onClick={handleDeleteAccounts}
                >
                    Change Connected Bank Account
                </Button>
            </Box>
        </Box>
    );
};

export default ManagePlaidAccount;

