import React from 'react';
import { Typography, Paper, Grid } from '@mui/material';

export const AllSplits = ({ splits, onSplitClick }) => {
  return (
    <Grid container spacing={3}>
      {splits.map(split => (
        <Grid item md={'Auto'} key={split.id} onClick={() => onSplitClick(split)}>
          <Paper sx={{ padding: 2, textAlign: 'center', minHeight: 120, minWidth: 150, cursor: 'pointer' }}>
            <Typography variant="h6" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {split.creatorName}
            </Typography>
            <Typography color="textSecondary">{split.status}</Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default AllSplits;






