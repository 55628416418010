import React, { useState } from "react";
import { SetPageTitle } from "@fireactjs/core";
import { Alert, Box, Button, Container, Grid, Paper, TextField, Typography } from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";

export const CreateSplit = () => {
    const [processing, setProcessing] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [managerSplit, setManagerSplit] = useState('');
    const [creatorSplit, setCreatorSplit] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setProcessing(true);
        setError(null);
        setSuccess(false);

        // Validate split percentages
        const managerSplitValue = parseFloat(managerSplit);
        const creatorSplitValue = parseFloat(creatorSplit);
        if (managerSplitValue + creatorSplitValue !== 100) {
            setError('Manager Split and Creator Split must add up to 100.');
            setProcessing(false);
            return;
        }

        try {
            const functions = getFunctions();
            const createSplit = httpsCallable(functions, 'createSplit');

            await createSplit({
                creatorName: name,
                email: email.toLowerCase(),
                managerSplit: managerSplitValue,
                creatorSplit: creatorSplitValue,
            });

            // Handle success
            setProcessing(false);
            setSuccess(true);
            // Clear form fields
            setName('');
            setEmail('');
            setManagerSplit('');
            setCreatorSplit('');
        } catch (error) {
            console.error('Error creating split:', error); // Log the full error object
            setError(error.message);
            setProcessing(false);
        }
    };

    return (
        <Container maxWidth="md">
            <SetPageTitle title="Create Split" />
            <Paper>
                <Box p={2}>
                    <Typography component="h1" variant="h4" align="center">Create Split</Typography>
                </Box>
                {error &&
                    <Box p={2}>
                        <Alert severity="error">{error}</Alert>
                    </Box>
                }
                {success &&
                    <Box p={2}>
                        <Alert severity="success">The split has been successfully created</Alert>
                    </Box>
                }
                <Box p={2}>
                    <TextField
                        required
                        fullWidth
                        name="name"
                        label="Creator Name"
                        type="text"
                        margin="normal"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        required
                        fullWidth
                        name="email"
                        label="Creator Email"
                        type="email"
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                required
                                fullWidth
                                name="managerSplit"
                                label="Manager Split (%)"
                                type="number"
                                margin="normal"
                                value={managerSplit}
                                onChange={(e) => setManagerSplit(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                required
                                fullWidth
                                name="creatorSplit"
                                label="Creator Split (%)"
                                type="number"
                                margin="normal"
                                value={creatorSplit}
                                onChange={(e) => setCreatorSplit(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box p={2}>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Button type="button" color="primary" variant="contained" disabled={processing} onClick={handleSubmit}>Create Split</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Container>
    );
};

export default CreateSplit;


