import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Button } from '@mui/material';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useLocation, useNavigate } from 'react-router-dom';
import AllSplits from './AllSplits';
import AllPendingSplits from './AllPendingSplits';
import DashboardGrid from './DashboardGrid';
import PayoutTable from './PayoutTable';
import GetTransactions from './getTransactions';
import useFetchUserRole from '../hooks/useUserRole'; // Ensure correct import

export const HomeDashboard = () => {
  const [dashboardData, setDashboardData] = useState({});
  const [splits, setSplits] = useState([]);
  const [pendingSplits, setPendingSplits] = useState([]);
  const [selectedSplit, setSelectedSplit] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { role, loading } = useFetchUserRole();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const functions = getFunctions();
        const getDashboardData = httpsCallable(functions, 'getDashboardData');
        const getUsersSplits = httpsCallable(functions, 'getUsersSplits');
        const getPendingSplits = httpsCallable(functions, 'getPendingSplits');

        const [dashboardResponse, splitsResponse, pendingSplitsResponse] = await Promise.all([
          getDashboardData(),
          getUsersSplits(),
          getPendingSplits(),
        ]);

        setDashboardData(dashboardResponse.data);
        setSplits(splitsResponse.data.splits);
        setPendingSplits(pendingSplitsResponse.data.pendingSplits);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [role]);

  useEffect(() => {
    if (location.pathname === '/') {
      setSelectedSplit(null);
    }
  }, [location.pathname]);

  const handleSplitClick = (split) => {
    setSelectedSplit(split);
  };

  if (loading) {
    return <Typography>Loading...</Typography>; // Add a loading state if necessary
  }

  if (pendingSplits.length > 0) {
    return (
      <Container maxWidth="lg">
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Pending Splits
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AllPendingSplits splits={pendingSplits} />
          </Grid>
        </Grid>
      </Container>
    );
  }

  if (!role) {
    return (
      <Container maxWidth="lg">
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Please select your role to continue.
        </Typography>
        <Button variant="contained" color="primary" onClick={() => navigate('/select-role')}>
          Select Role
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <AllSplits splits={splits} onSplitClick={handleSplitClick} />
        </Grid>
        <Grid item xs={12} sm={8}>
          {selectedSplit ? (
            <PayoutTable split={selectedSplit} />
          ) : (
            <>
              <Typography component="h1" variant="h4" align="center" gutterBottom>
                Hey! Here’s the latest.
              </Typography>
              <DashboardGrid dashboardData={dashboardData} />
            </>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        <Grid item xs={12}>
          <GetTransactions />
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomeDashboard;





