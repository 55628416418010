import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

export const Help = () => {
    return (
        <Box
            sx={{
                maxWidth: '400px',
                margin: 'auto',
                padding: '20px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                textAlign: 'center',
            }}
        >
            <Typography variant="h4" gutterBottom>
                Need Help?
            </Typography>
            <Typography variant="body1" paragraph>
                If you need assistance, please contact us:
            </Typography>
            <Box sx={{ marginBottom: '16px' }}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<EmailIcon />}
                    href="mailto:drew@splvts.com"
                    sx={{ marginBottom: '8px' }}
                >
                    Email Drew
                </Button>
            </Box>
            <Box>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<PhoneIcon />}
                    href="tel:+19252977130"
                >
                    Call 925-297-7130
                </Button>
            </Box>
        </Box>
    );
};

export default Help;
