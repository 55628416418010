import React from "react";
import { List, ListItem, Card } from '@mui/material';
import AcceptSplitComponent from "./AcceptSplitComponent";

export const AllPendingSplits = ({ splits }) => {

    return (
        <List>
            {splits.map((split) => (
                <ListItem key={split.id} style={{ padding: 0 }}>
                    <Card variant="outlined" style={{ width: '100%' }}>
                        <AcceptSplitComponent splitData={split} />
                    </Card>
                </ListItem>
            ))}
        </List>
    );
};

export default AllPendingSplits;

