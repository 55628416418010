import React from 'react';

export const LandingPage = () => {
    return (
        <div>
            <h1>Welcome to Splvts</h1>
            <p>Here is some information about our service...</p>
        </div>
    );
};

export default LandingPage;