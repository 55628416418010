import AccountTreeIcon from "@mui/icons-material/AccountTree";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import { getLinkToken, storeAccount } from "../plaidUtils";
export default function LinkAccount() {
  const [token, setToken] = useState();
  const config = {
    onSuccess: async (public_token, metadata) => {
      setToken();

      // no need to await as you are expecting data from webhook
      storeAccount(metadata);
      window.location.reload(); // Refresh the page
    },
    onExit: (err, metadata) => {
      setToken();
    },
    onEvent: (eventName, metadata) => {
      if (eventName === "EXIT") {
        setToken();
      }
    },
    token,
  };
  const handleGetToken = async () => {
    try {
      const link = await getLinkToken();
      console.log(link.link_token)
      setToken(link.link_token);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const { open, ready } = usePlaidLink(config);
  if (ready && token) {
    open();
  }
  return (
    <ListItemButton onClick={handleGetToken}>
      <ListItemIcon>
        <AccountTreeIcon />
      </ListItemIcon>
      <ListItemText
        primary={<Typography color="textPrimary">Link Bank Account</Typography>}
      />
    </ListItemButton>
  );
}
