import React from 'react';
import { Container, Typography, Box } from '@mui/material';

export const PrivacyPolicy = () => {
  return (
    <Container>
      <Box mt={4} mb={4}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to splvts.com. This privacy policy explains how we collect, use, and share your personal information when you use our website and services.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect information you provide directly to us, such as when you create an account, update your profile, use our services, or communicate with us. This may include your name, email address, phone number, and other contact information.
        </Typography>
        <Typography variant="h5" gutterBottom>
          How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use the information we collect to provide, maintain, and improve our services, to communicate with you, and to protect splvts.com and our users.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Sharing Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We do not share your personal information with third parties except as necessary to provide our services, comply with the law, or protect our rights.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Your Choices
        </Typography>
        <Typography variant="body1" paragraph>
          You can update your account information at any time by logging into your account and updating your profile. You can also contact us to request access to or deletion of your personal information.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Changes to This Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about this privacy policy, please contact us at support@splvts.com.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
