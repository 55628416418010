import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  Button,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AddIcon from "@mui/icons-material/Add";
import { FireactContext } from "@fireactjs/core";
import LinkAccount from "./linkAccount";
import useFetchUserRole from "../hooks/useUserRole";
import useFetchLinkedAccount from "../hooks/useLinkedAccount ";
import { getFunctions, httpsCallable } from 'firebase/functions';

export const MainMenu = ({ customItems }) => {
  const { config } = useContext(FireactContext);
  const { role, loading: roleLoading } = useFetchUserRole();
  const { linkedAccount, loading: linkedAccountLoading } = useFetchLinkedAccount();
  const [pendingSplits, setPendingSplits] = useState([]);
  const pathnames = config.pathnames;
  const profileUrl = pathnames.UserProfile;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const functions = getFunctions();
        const getPendingSplits = httpsCallable(functions, 'getPendingSplits');

        const pendingSplitsResponse = await getPendingSplits();

        setPendingSplits(pendingSplitsResponse.data.pendingSplits);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  if (roleLoading || linkedAccountLoading) {
    return <Typography>Loading...</Typography>; // Add a loading state if necessary
  }

  const showLimitedMenu = pendingSplits.length > 0;

  return (
    <List component="nav">
      <ListItemButton onClick={() => navigate('/', { state: { role } })}>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary={<Typography color="textPrimary">Home</Typography>} />
      </ListItemButton>
      <NavLink to={profileUrl} style={{ textDecoration: "none" }} key="profile">
        <ListItemButton>
          <ListItemIcon>
            <AccountBoxIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography color="textPrimary">My Profile</Typography>} />
        </ListItemButton>
      </NavLink>
      {!showLimitedMenu && (
        <>
          {customItems}
          {role === 'Manager' && (
            <NavLink to="/create-split" style={{ textDecoration: "none" }} key="create-split">
              <ListItemButton>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary={<Typography color="textPrimary">Create Split</Typography>} />
              </ListItemButton>
            </NavLink>
          )}
        </>
      )}
      {linkedAccount ? (
        <Box mt={2} sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/manage_plaid_account')}
            sx={{
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              maxWidth: '200px', // Adjust the max width as needed
            }}
          >
            Manage Connected Bank Account
          </Button>
        </Box>
      ) : (
        <LinkAccount />
      )}
      <Box mt={2} sx={{ textAlign: 'center' }}>
        <Button variant="contained" color="primary" onClick={() => navigate('/help')}>
          Need Help?
        </Button>
      </Box>
    </List>
  );
};

export default MainMenu;






