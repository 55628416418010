import { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

export const useFetchUserRole = () => {
    const [role, setRole] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserRole = async () => {
            try {
                const auth = getAuth();
                const user = auth.currentUser;
                if (user) {
                    const functions = getFunctions();
                    const getUserRole = httpsCallable(functions, 'getUserRole');
                    const response = await getUserRole();
                    setRole(response.data.role);
                } else {
                    setRole(null);
                }
            } catch (error) {
                console.error('Error fetching user role:', error);
                setRole(null);
            } finally {
                setLoading(false);
            }
        };

        fetchUserRole();
    }, []);

    return { role, loading };
};

export default useFetchUserRole;





