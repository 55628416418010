import axios from "axios";
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

// TODO the below url is your server url
export const API_URL = "https://us-central1-splvt-c5003.cloudfunctions.net";

export const getLinkToken = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/api/create-link-token`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
};

// Function to store the account with the Firebase user ID
export const storeAccount = async (values) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      throw new Error("No user is signed in.");
    }

    const uid = user.uid;
    const payload = {
      ...values,
      userId: uid, // Add the Firebase user ID to the payload
    };

    const { status } = await axios.post(`${API_URL}/api/exchange-token`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return status;
  } catch (error) {
    console.error("Error storing account:", error);
    throw error;
  }
};

export const getTransactions = async () => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      throw new Error("No user is signed in.");
    }

    // Initialize Firebase Functions
    const functions = getFunctions();
    const getLinkedAccount = httpsCallable(functions, 'getLinkedAccount');

    // Call the getLinkedAccount function
    const linkedAccountResponse = await getLinkedAccount();
    console.log(linkedAccountResponse.data.linkedAccount)

    const itemId = linkedAccountResponse.data.linkedAccount.itemId;
    console.log(itemId)

    const transactions = await axios.post(`${API_URL}/api/webhook`, {
      "webhook_type": "TRANSACTIONS",
      "webhook_code": "SYNC_UPDATES_AVAILABLE",
      "item_id": itemId,
      "initial_update_complete": true,
      "historical_update_complete": false,
      "environment": "production"
    }, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log('Get transactions data: ', transactions);
    return transactions.data;
  } catch (error) {
    console.log(error)
    console.error("Error fetching transactions:", error);
    throw error;
  }
};