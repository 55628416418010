import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import PaymentElements from "./PaymentElements";
const stripePublishedKey = process.env.REACT_APP_STRIPE_PUBLISHED_KEY;

export default function PaymentComponent({ clientSecret }) {
  const [stripePromise, setStripePromise] = useState(null); 

  useEffect(() => {
    setStripePromise(loadStripe(stripePublishedKey));
  }, []);

  if (clientSecret && stripePromise)
  return (
    <div style={{maxWidth: "100%"}}>
      <PaymentElements
      stripePromise={stripePromise}
      clientSecret={clientSecret}
    />
    </div>
  );
}
