import React, { useState } from "react";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
  createStripeAccount,
  createStripeCustomer,
  getStripeCustomerOnboardingLink,
} from "../services/payment";

export const SelectRole = () => {
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const auth = getAuth();
    const db = getFirestore();
    const user = auth.currentUser;

    if (!user) {
      setLoading(false);
      console.error("No user is signed in");
      return;
    }

    const email = user.email;
    const name = user.displayName;
    const userRef = doc(db, "users", user.uid);

    try {
      // when accepting the role, you can create stripe account or stripe customer based on the role they select.
      if (role === "Manager") {
        // if selected Manager, create stripe account and store the accountId in db linked to current user
        const accountId = await createStripeAccount(email);

        // Optionally, ask the user to finish their stripe onboarding
        const link = await getStripeCustomerOnboardingLink(accountId)
        console.log(accountId);
        await updateDoc(userRef, {
          stripeManagerAccountId: accountId,
          role,
          stripeManagerAccountLink: link
        });

        // navigate("/");
        // window.location.reload(); 

        // Ensure the link is properly formed and redirect
        if (link) {
          window.location.href = link;
        } else {
          console.error("Stripe onboarding link is invalid");
        }
      }

      if (role === "Creator") {
        // if selected Creator, create stripe customer and store the customerId in db linked to current user
        const customerId = await createStripeCustomer(email, name);
        await updateDoc(userRef, {
          stripeCreatorCustomerId: customerId,
          role
        });

        console.log({ customerId });
        navigate("/", { state: { role } });
        window.location.reload(); // Reload the app after setting the role
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error creating Stripe account or updating Firestore:", error);
    }
  };

  return (
    <div>
      <h2>Select Your Role</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            <input
              type="radio"
              value="Manager"
              checked={role === "Manager"}
              onChange={handleRoleChange}
            />
            Manager
          </label>
        </div>
        <div>
          <label>
            <input
              type="radio"
              value="Creator"
              checked={role === "Creator"}
              onChange={handleRoleChange}
            />
            Creator
          </label>
        </div>
        <button type="submit" disabled={loading}>
          {loading ? "Saving..." : "Save Role"}
        </button>
      </form>
    </div>
  );
};

export default SelectRole;
