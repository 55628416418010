import axios from "axios";
export const API_URL = "https://us-central1-splvt-c5003.cloudfunctions.net";

export const getPaymentIntent = async (values) => {
  try {
    const { data } = await axios.post(
      `${API_URL}/api/create-payment-intent`,
      values
    );
    return data;
  } catch (e) {
    throw e;
  }
};

export const getStripeCustomerOnboardingLink = async (accountId) => {
  try {
    const { data } = await axios.post(`${API_URL}/api/onboard-stripe-account`, {
      accountId,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const createStripeAccount = async (email) => {
  try {
    const { data } = await axios.post(`${API_URL}/api/create-stripe-account`, { email });
    return data;
  } catch (e) {
    throw e;
  }
};

export const createStripeCustomer = async (email, name) => {
  try {
    const { data } = await axios.post(`${API_URL}/api/create-stripe-customer`, {
      email,
      name,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getStripeCustomer = async (id) => {
  try {
    const { data } = await axios.post(`${API_URL}/api/get-stripe-customer`, { id });
    return data;
  } catch (e) {
    throw e;
  }
};


export const getClientSecret = async (customerId) =>{
  try {
    const { data } = await axios.post(`${API_URL}/api/create-setup-intent`, { customerId });
    return data;
  } catch (e) {
    throw e;
  }
}

export const checkPaymentMethod = async (customerId) =>{
  try {
    const { data } = await axios.post(`${API_URL}/api/check-payment-method`, { customerId });
    console.log('Check payment method data: ', data)
    return data;
  } catch (e) {
    throw e;
  }
}